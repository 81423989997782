import getLatinized from '../lib/getLatinized';

const reviewers = [
    {
        sort: 'Alechnowicz-Skrzypek Iwona',
        label: {
            pl: 'Dr hab. Iwona Alechnowicz-Skrzypek, prof. UO, Uniwersytet Opolski, Polska',
            en: 'Prof. dr hab. Iwona Alechnowicz-Skrzypek, University of Opole, Poland',
            de: 'Dr. habil. Iwona Alechnowicz-Skrzypek, Univ.-Prof., Universität Oppeln, Polen'
        }
    },
    {
        sort: 'Androutsopoulos Jannis',
        label: {
            pl: 'Prof. dr Jannis Androutsopoulos, Uniwersytet w Hamburgu, Niemcy',
            en: 'Prof. dr Jannis Androutsopoulos, University of Hamburgu, Germany',
            de: 'Prof. Dr. Jannis Androutsopoulos, Universität Hamburg, Deutschland'
        }
    },
    {
        sort: 'Aptacy Jarosław',
        label: {
            pl: 'Dr hab. Jarosław Aptacy, Uniwersytet Adama Mickiewicza w Poznaniu, Polska',
            en: 'Dr hab. Jarosław Aptacy, Adam Mickiewicz University, Poland',
            de: 'Dr. habil. Jarosław Aptacy, Adam-Mickiewicz-Universität Posen, Polen'
        }
    },
    {
        sort: 'Bacuvciková Petra',
        label: {
            pl: 'Dr Petra Bacuvciková, Uniwersytet w Ołomuńcu, Rep. Czeska',
            en: 'Dr Petra Bacuvciková, Palacký University in Olomouc, Czech Republic',
            de: 'Dr. Petra Baciuvciková, Palacky-Universität Olmütz, Tschechische Republik'
        }
    },
    {
        sort: 'Badstübner-Kizik Camila',
        label: {
            pl: 'Dr hab. Camila Badstübner-Kizik, prof. UAM, Uniwersytet im. Adama Mickiewicza w Poznaniu, Polska',
            en: 'Prof. dr hab. Camila Badstübner-Kizik, Adam Mickiewicz University, Poland',
            de: 'Univ.-Prof. Dr. habil. Camila Badstübner-Kizik, Adam-Mickiewicz-Universität, Posen, Polen'
        }
    },
    {
        sort: 'Bąk Paweł',
        label: {
            pl: 'Dr hab. Paweł Bąk, prof. Urz, Uniwersytet Rzeszowski, Polska',
            en: 'Prof. dr Paweł Bąk, University of Rzeszów, Poland',
            de: 'Dr. habil. Paweł Bąk, Univ.-Prof., Universität Rzeszów, Polen'
        }
    },
    {
        sort: 'Baran-Łucarz Małgorzata',
        label: {
            pl: 'Dr Małgorzata Baran-Łucarz, Uniwersytet Wrocławski, Polska',
            en: 'Dr Małgorzata Baran-Łucarz, University of Wrocław, Poland',
            de: 'Dr. Małgorzata Baran-Łucarz, Universität Wrocław, Polen'
        }
    },
    {
        sort: 'Berdychowska Zofia',
        label: {
            pl: 'Prof. dr hab. Zofia Berdychowska, Uniwesytet Jagielloński, Polska',
            en: 'Prof. dr Zofia Berdychowska, Jagiellonian University, Poland',
            de: 'Prof. Dr. Zofia Berdychowska, Jagiellonen-Universität, Polen'
        }
    },
    {
        sort: 'Bilut-Homplewicz Zofia',
        label: {
            pl: 'Prof. dr hab. Zofia Bilut-Homplewicz, Uniwersytet Rzeszowski, Polska',
            en: 'Prof. dr Zofia Bilut-Homplewicz, University of Rzeszów, Poland',
            de: 'Prof. Dr. Zofia Bilut-Homplewicz, Universität Rzeszów, Polen'
        }
    },
    {
        sort: 'Biolik Maria',
        label: {
            pl: 'Prof. dr hab. Maria Biolik, Uniwersytet Warmińsko-Mazurski w Olsztynie, Polska',
            en: 'Prof. dr Maria Biolik, University of Warmia and Mazury in Olsztyn, Poland',
            de: 'Prof. Dr. Maria Biolik, Warmia und Mazury-Universität, Olsztyn, Polen'
        }
    },
    {
        sort: 'Biskup Maria',
        label: {
            pl: 'Dr hab. Maria Biskup, Uniwersytet Warszawski, Polska',
            en: 'Dr hab. Maria Biskup, University of Warsaw, Poland',
            de: 'Dr. habil. Maria Biskup, Universität Warschau, Polen'
        }
    },
    {
        sort: 'Biszczanik Marek',
        label: {
            pl: 'Dr hab. Marek Biszczanik, Uniwersytet Zielonogórski, Polska',
            en: 'Dr hab. Marek Biszczanik, University of Zielona Góra, Poland',
            de: 'Dr. habil. Marek Biszczanik, Universität Zielona Góra, Polen'
        }
    },
    {
        sort: 'Bohušová Zuzana',
        label: {
            pl: 'Prof. dr hab. Zuzana Bohušová, Uniwersytet Mateja Bela w Bańskiej Bystrzycy, Słowacja',
            en: 'Prof. dr Zuzana Bohušová, Matej Bel University in Banská Bystrica, Slovakia',
            de: 'Prof. Dr. Zuzana Bohušová, Matej-Bel-Universität Banská Bystrica, Slowakei'
        }
    },
    {
        sort: 'Bonacchi Silvia',
        label: {
            pl: 'Dr hab. Silvia Bonacchi, prof. UW, Uniwersytet Warszawski, Polska',
            en: 'Prof. dr Silvia Bonacchi, University of Warsaw, Poland',
            de: 'Dr. habil. Silvia Bonacchi, Univ.-Prof., Universität Warschau, Polen'
        }
    },
    {
        sort: 'Brdar-Szabó Rita',
        label: {
            pl: 'Dr hab. Rita Brdar-Szabó, Uniwersytet im. Loránda Eötvösa w Budapeszcie, Węgry',
            en: 'Dr hab. Rita Brdar-Szabó, Eötvös Loránd University Budapest, Hungary',
            de: 'Dr. habil. Rita Brdar-Szabó, Eötvös Loránd Universität in Budapest, Ungarn'
        }
    },
    {
        sort: 'Bubenhofer Noah',
        label: {
            pl: 'Prof. dr hab. Noah Bubenhofer, Uniwersytet w Zurychu, Szwajcaria',
            en: 'Prof. dr hab. Noah Bubenhofer, University of Zürich, Switzerland',
            de: 'Prof. Dr. Noah Bubenhofer, Universität Zürich, Schweiz'
        }
    },
    {
        sort: 'Budzyńska-Daca Agnieszka',
        label: {
            pl: 'Dr hab. Agnieszka Budzyńska-Daca, Uniwersytet Warszawski, Polska',
            en: 'Prof. dr hab. Agnieszka Budzyńska-Daca, University of Warsaw, Poland',
            de: 'Dr. habil. Agnieszka Budzyńska-Daca, Univ.-Prof., Universität Warschau, Polen'
        }
    },
    {
        sort: 'Byelozyorova Olena',
        label: {
            pl: 'Dr Olena Byelozyorova, Charkowski Uniwersytet Narodowy im. Wasyla Karazina, Ukraina',
            en: 'Dr Olena Byelozyorova, V. N. Karazin Kharkiv National University, Ukraine',
            de: 'Dr. Olena Byelozyorova, Nationale W.-N.-Karasin-Universität Charkiw, Ukraine'
        }
    },
    {
        sort: 'Choromański Maciej',
        label: {
            pl: 'Dr Maciej Choromański, TUDIAS TU Dresden, Institute of Advanced Studies GmbH, Niemcy',
            en: 'Dr Maciej Choromański, TUDIAS TU Dresden, Institute of Advanced Studies GmbH, Germany',
            de: 'Dr. Maciej Choromański, TUDIAS TU Dresden, Institute of Advanced Studies GmbH, Deutschland'
        }
    },
    {
        sort: 'Chruszczewski Piotr',
        label: {
            pl: 'Prof. dr hab. Piotr Chruszczewski, Uniwersytet Wrocławski, Polska',
            en: 'Prof. dr Piotr Chruszczewski, University of Wrocław, Poland',
            de: 'Prof. Dr. Piotr Chruszczewski, Universität Wrocław, Polen'
        }
    },
    {
        sort: 'Cieszkowski Marek',
        label: {
            pl: 'Dr hab. Marek Cieszkowski, prof. UKW w Bydgoszczy, Polska',
            en: 'Prof. dr Marek Cieszkowski, Kazimierz Wielki University in Bydgoszcz, Poland',
            de: 'Dr. habil. Marek Cieszkowski, Univ.-Prof., Kazimierz-Wielki-Universität in Bydgoszcz, Polen'
        }
    },
    {
        sort: 'Czachur Waldemar',
        label: {
            pl: 'Dr hab. Waldemar Czachur, Prof. UW, Uniwersytet Warszawski, Polska',
            en: 'Prof. dr Waldemar Czachur, University of Warsaw, Poland',
            de: 'Dr. habil. Waldemar Czachur, Univ.-Prof., Universität Warschau, Polen'
        }
    },
    {
        sort: 'Dąbrowska-Burkhardt Jarochna',
        label: {
            pl: 'Dr hab. Jarochna Dąbrowska-Burkhardt, prof. UZ, Uniwersytet Zielonogórski, Polska',
            en: 'Prof. dr Jarochna Dąbrowska-Burkhardt, University of Zielona Góra, Poland',
            de: 'Dr. habil. Jarochna Dąbrowska-Burkhardt, Univ.-Prof., Universität Zielona Góra, Polen'
        }
    },
    {
        sort: 'Dalmas Martine',
        label: {
            pl: 'Prof. dr hab. Martine Dalmas, Uniwersytet Paryski – Sorbonne, Francja',
            en: 'Prof. dr Martine Dalmas, Paris-Sorbonne University, France',
            de: 'Prof. Dr. habil. Martine Dalmas, Universität Paris-Sorbonne, Frankreich'
        }
    },
    {
        sort: 'Dampc-Jarosz Renata',
        label: {
            pl: 'Dr hab. Renata Dampc-Jarosz, Prof. UŚ, Uniwersytet Śląski w Katowicach, Polska',
            en: 'Prof. dr hab. Renata Dampc-Jarosz, University of Silesia Katowice, Poland',
            de: 'Dr. habil. Renata Dampc-Jarosz, Schlesiche Universität Katowice, Polen'
        }
    },
    {
        sort: 'Dargiewicz Anna',
        label: {
            pl: 'Dr hab. Anna Dargiewicz, prof. UWM, Uniwersytet Warmińsko-Mazurski w Olsztynie, Polska',
            en: 'Prof. dr Anna Dargiewicz, University of Warmia and Mazury in Olsztyn, Poland',
            de: 'Dr. habil. Anna Dargiewicz, Univ.-Prof., Warmia und Mazury-Universität, Olsztyn, Polen'
        }
    },
    {
        sort: 'Demčišák Ján',
        label: {
            pl: 'Dr Ján Demčišák, Uniwersytet Cyryla i Metodego w Trnavie, Słowacja',
            en: 'Dr Ján Demčišák, University of Ss. Cyril and Methodius in Trnava, Slovakia',
            de: 'Dr. Ján Demčišák, Universität der Heiligen Kyrill und Method in Trnava, Slowakei'
        }
    },
    {
        sort: 'Domínguez Vásquez Maria José',
        label: {
            pl: 'Prof. dr hab. Maria José Domínguez Vásquez, Uniwersytet Santiago de Compostela, Hiszpania',
            en: 'Prof. dr Maria José Domínguez Vásquez, University of Santiago de Compostela, Spain',
            de: 'Prof. Dr. habil. Maria José Domínguez Vásquez, Universität Santiago de Compostela, Spanien'
        }
    },
    {
        sort: 'Drewnowska-Vargáné Ewa',
        label: {
            pl: 'Dr hab. Ewa Drewnowska-Vargáné, Uniwersytet w Segedynie, Węgry',
            en: 'Dr Ewa Drewnowska-Vargáné, University of Szeged, Hungary',
            de: 'Dr. habil. Ewa Drewnowska-Vargáné, Universität Szeged, Ungarn'
        }
    },
    {
        sort: 'Dyakiv Khrystyna',
        label: {
            pl: 'Prof. dr hab. Khrystyna Dyakiv, Lwowski Uniwersytet Narodowy im. Iwana Franki we Lwowie, Ukraina',
            en: 'Prof. dr Khrystyna Dyakiv, Iwan Franko University, Ukraine',
            de: 'Prof. Dr. Khrystyna Dyakiv, Nationale Iwan-Franko-Universität Lviv, Ukraine'
        }
    },
    {
        sort: 'Filatkina Natalia',
        label: {
            pl: 'Prof. dr hab. Natalia Filatkina, Uniwersytet w Hamburgu, Niemcy',
            en: 'Prof. Dr. Natalia Filatikina, Universität Hamburg, Deutschland',
            de: 'Prof. dr Natalia Filatkina, University of Hamburg, Germany'
        }
    },
    {
        sort: 'Frączek Agnieszka',
        label: {
            pl: 'Dr hab. Agnieszka Frączek, Uniwersytet Warszawski, Polska',
            en: 'Dr hab. Agnieszka Frączek, University of Warsaw, Poland',
            de: 'Dr. habil. Agnieszka Frączek, Universität Warschau, Polen'
        }
    },
    {
        sort: 'Frankowska Violetta',
        label: {
            pl: 'Dr Violetta Frankowska, Uniwersytet im. Adama Mickiewicza w Poznaniu, Polska',
            en: 'Dr Violetta Frankowska, Adam Mickiewicz University in Poznań, Poland',
            de: 'Dr. Violetta Frankowska, Adam-Mickiewicz-Universität in Posen, Polen'
        }
    },
    {
        sort: 'Garcarz Michał',
        label: {
            pl: 'Dr hab. Michał Garcarz, prof. UWr, Universytet Wrocławski, Polska',
            en: 'Prof. dr Michał Garcarz, prof. UWr, University of Wrocław, Poland',
            de: 'Univ.-Prof. Dr. habil. Michał Garcarz, Universität Wrocław, Polen'
        }
    },
    {
        sort: 'Gautier Laurent',
        label: {
            pl: 'Dr hab. Laurent Gautier, prof. ucz., Uniwersytet Burgundii, Francja',
            en: 'Prof. dr hab. Laurent Gautier, University of Burgundy, France',
            de: 'Univ. Prof.-Dr. Laurent Gautier, Universität von Burgund, Frankreich'
        }
    },
    {
        sort: 'Gębal Przemysław',
        label: {
            pl: 'Dr hab. Przemysław Gębal, prof. UW, Uniwersytet Jagielloński, Polska',
            en: 'Prof. dr Przemysław Gębal, Jagiellonian University, Poland',
            de: 'Dr. habil. Przemysław Gębal, Univ.-Prof., Jagiellonen-Universität, Polen'
        }
    },
    {
        sort: 'Geller Ewa',
        label: {
            pl: 'Prof. dr hab. Ewa Geller, Uniwersytet Warszawski, Polska',
            en: 'Prof. dr hab. Ewa Geller, University of Warsaw, Poland',
            de: 'Prof. dr hab. Ewa Geller, Universität Warschau, Polen'
        }
    },
    {
        sort: 'Gierzyńska Marta',
        label: {
            pl: 'Dr Marta Gierzyńska, Uniwersytet Warmińsko-Mazurski w Olsztynie, Polska',
            en: 'Dr Marta Gierzyńska, Warmia and Mazury University in Olsztyn, Poland',
            de: 'Dr. Marta Gierzyńska, Warmia und Mazury-Universität in Olsztyn, Polen'
        }
    },
    {
        sort: 'Góźdź-Roszkowski Stanisław',
        label: {
            pl: 'Dr hab. Stanisław Góźdź-Roszkowski, prof. UŁ, Uniwersytet Łódzki, Polska',
            en: 'Prof. dr Stanisław Góźdź-Roszkowski, University of Łódź, Poland',
            de: 'Dr. habil. Stanisław Góźdź-Roszkowski, Univ.-Prof., Universität Lodz, Polen'
        }
    },
    {
        sort: 'Greule Albrecht',
        label: {
            pl: 'Prof. dr hab. Albrecht Greule, Uniwersytet w Ratyzbonie, Niemcy',
            en: 'Prof. dr hab. Albrecht Greule, University of Regensburg, Germany',
            de: 'Prof. Dr. Albrecht Greule, Universität Regensburg, Deutschland'
        }
    },
    {
        sort: 'Grzeszczakowska-Pawlikowska Beata',
        label: {
            pl: 'Dr hab. Beata Grzeszczakowska-Pawlikowska, Uniwersytet Łódzki, Polska',
            en: 'Prof. dr hab. Beata Grzeszczakowska-Pawlikowska, University of Łódź, Poland',
            de: 'Dr. habil. Beata Grzeszczakowska-Pawlikowska, Universität Łódź, Polen'
        }
    },
    {
        sort: 'Grzywka Katarzyna',
        label: {
            pl: 'Prof. dr hab. Katarzyna Grzywka, Uniwersytet Warszawski, Polska',
            en: 'Prof. dr hab. Katarzyna Grzywka, University of Warsaw, Poland',
            de: 'Prof. Dr. Katarzyna Grzywka, Universität Warschau, Polen'
        }
    },
    {
        sort: 'Guławska-Gawkowska Małgorzata',
        label: {
            pl: 'Dr hab. Małgorzata Guławska-Gawkowska, Uniwersytet Warszawski, Polska',
            en: 'Prof. dr Małgorzata Guławska-Gawkowska, University of Warsaw, Poland',
            de: 'Dr. habil. Małgorzata Guławska-Gawkowska, Universität Warschau, Polen'
        }
    },
    {
        sort: 'Hałub Marek',
        label: {
            pl: 'Prof. dr hab. dr h. c. Marek Hałub, Uniwersytet Wrocławski, Polska',
            en: 'Prof. dr hab. dr h. c. Marek Hałub, University of Wrocław, Poland',
            de: 'Prof. Dr. Dr. h. c. Marek Hałub, Universität Wrocław, Polen'
        }
    },
    {
        sort: 'Hanus Anna',
        label: {
            pl: 'Dr hab. Anna Hanus, prof. URz, Uniwersytet Rzeszowski, Polska',
            en: 'Prof. dr hab. Anna Hanus, University of Rzeszów, Poland',
            de: 'Dr. habil. Anna Hanus, Univ.-Prof., Universität Rzeszów, Polen'
        }
    },
    {
        sort: 'Havryliv Oksana',
        label: {
            pl: 'Dr Oksana Havryliv, Uniwersytet Wiedeński, Austria',
            en: 'Dr Oksana Havryliv, University of Vienna, Austria',
            de: 'Dr. Okasana Havryliv, Universität Wien, Österreich'
        }
    },
    {
        sort: 'Höppnerová Věra',
        label: {
            pl: 'Prof. dr hab. Věra Höppnerová, Uniwersytet Ekonomiczny w Pradze, Republika Czeska',
            en: 'Prof. dr hab. Věra Höppnerová, University of Economics Prague, Czech Republic',
            de: 'Prof. Dr. Věra Höppnerová, Ökonomische Universität Prag, Tschechische Republik'
        }
    },
    {
        sort: 'Hornáček Banášová Monika',
        label: {
            pl: 'Dr Monika Hornáček Banášová, Uniwersytet Cyryla i Metodego w Trnavie, Słowacja',
            en: 'Dr Monika Hornáček Banášová, University of Ss. Cyril and Methodius in Trnava, Slovakia',
            de: 'Dr. Monika Hornáček Banášová, Universität der Heiligen Kyrill und Method in Trnava, Slowakei'
        }
    },
    {
        sort: 'Janíková Věra',
        label: {
            pl: 'Prof. dr Věra Janíková, Uniwersytet Masaryka w Brnie, Republika Czeska',
            en: 'Prof. dr Věra Janíková, Masaryk University in Brno, Czech Republic',
            de: 'Prof. Dr. Věra Janíková, Masaryk Universität in Brünn, Tschechische Republik'
        }
    },
    {
        sort: 'Janus Dominika',
        label: {
            pl: 'Dr Dominika Janus, Uniwersytet Gdański, Polska',
            en: 'Dr Dominika Janus, University of Gdańsk, Poland',
            de: 'Dr. Dominika Janus, Universität Gdańsk, Polen'
        }
    },
    {
        sort: 'Jarosz Beata',
        label: {
            pl: 'Dr Beata Jarosz, Uniwersytet Marii Curie-Skłodowskiej, Polska',
            en: 'Dr Beata Jarosz, Maria Curie-Skłodowska University, Poland',
            de: 'Dr. Beata Jarosz, Maria-Curie-Skłodowska-Universität in Lublin, Polen'
        }
    },
    {
        sort: 'Jarosz Józef',
        label: {
            pl: 'Dr hab. Józef Jarosz, prof. UWr, Uniwersytet Wrocławski, Polska',
            en: 'Prof. dr hab. Józef Jarosz, University of Wrocław, Poland',
            de: 'Univ.-Prof. Dr. habil. Józef Jarosz, Universität Wrocław, Polen'
        }
    },
    {
        sort: 'Jarosz Józef',
        label: {
            pl: 'Dr hab. Józef Jarosz, prof. UWr, Uniwersytet Wrocławski, Polska',
            en: 'Prof. dr Józef Jarosz, University of Wrocław, Poland',
            de: 'Univ.-Prof. Dr. habil. Józef Jarosz, Universität Wrocław, Polen'
        }
    },
    {
        sort: 'Jaroszewska Anna',
        label: {
            pl: 'Dr hab. Anna Jaroszewska, Uniwersytet Warszawski, Polska',
            en: 'Prof. dr hab. Anna Jaroszewska, University of Warsaw, Poland',
            de: 'Dr. habil. Anna Jaroszewska, Universität Warschau, Polen'
        }
    },
    {
        sort: 'Jaworska Mariola',
        label: {
            pl: 'Dr hab. Mariola Jaworska, prof. UWM, Uniwersytet Warmińsko-Mazurski w Olsztynie, Polska',
            en: 'Prof. dr Mariola Jaworska, Warmia and Mazury University in Olsztyn, Poland',
            de: 'Univ.-Prof. Dr. habil. Mariola Jaworska, Warmia und Mazury-Universität in Olsztyn, Polen'
        }
    },
    {
        sort: 'Jedynak Małgorzata',
        label: {
            pl: 'Dr Małgorzata Jedynak, Uniwersytet Wrocławski, Polska',
            en: 'Dr Małgorzata Jedynak, University of Wrocław, Poland',
            de: 'Dr. Małgorzata Jedynak, Universität Wrocław, Polen'
        }
    },
    {
        sort: 'Jurewicz Magdalena',
        label: {
            pl: 'Dr hab. Magdalena Jurewicz, Uniwersytet im. Adama Mickiewicza w Poznaniu, Polska',
            en: 'Dr hab. Magdalena Jurewicz, Adam Mickiewicz University, Poland',
            de: 'Dr. habil. Magdalena Jurewicz, Adam-Mickiewicz-Universität, Posen, Polen'
        }
    },
    {
        sort: 'Just Anna',
        label: {
            pl: 'Dr hab. Anna Just, Uniwersytet Warszawski, Polska',
            en: 'Dr hab. Anna Just, University of Warsaw, Poland',
            de: 'Dr. habil. Anna Just, Universität Warschau, Polen'
        }
    },
    {
        sort: 'Kaczmarek Dorota',
        label: {
            pl: 'Dr hab. Dorota Kaczmarek, Uniwersytet Łódzki, Polska',
            en: 'Dr hab. Dorota Kaczmarek, University of Łódź, Poland',
            de: 'Dr. habil. Dorota Kaczmarek, Universität Lodz, Polen'
        }
    },
    {
        sort: 'Kaczmarek Hanna',
        label: {
            pl: 'Dr hab. Hanna Kaczmarek, Uniwersytet Humanistyczno-Przyrodniczy im. Jana Długosza w Czestochowie, Polska',
            en: 'Dr hab. Hanna Kaczmarek, Jan Dlugosz University in Czestochowa, Poland',
            de: 'Dr. habil. Hanna Kaczmarek, Jan-Długosz-Universität, Polen'
        }
    },
    {
        sort: 'Karpiński Maciej',
        label: {
            pl: 'Dr hab. Maciej Karpiński, prof. UAM, Uniwersytet im. Adama Mickiewicza w Poznaniu, Polska',
            en: 'Prof. dr hab. Maciej Karpiński, Adam Mickiewicz University, Poznań, Poland',
            de: 'Univ.-Prof. Dr. habil. Maciej Karpiński, Adam-Mickiewicz-Universität in Poznań, Polen'
        }
    },
    {
        sort: 'Kątny Andrzej',
        label: {
            pl: 'Prof. dr hab. Andrzej Kątny, Uniwersytet Gdański, Polska',
            en: 'Prof. dr Andrzej Kątny, University of Gdańsk, Poland',
            de: 'Prof. Dr. habil. Andrzej Kątny, Universität Danzig, Polen'
        }
    },
    {
        sort: 'Kegyes Erika',
        label: {
            pl: 'Dr Erika Kegyes, Uniwersytet w Miszkolcu, Węgry',
            en: 'Dr Erika Kegyes, University of Miskolc, Hungary',
            de: 'Dr. Erika Kegyes, Universität Miskolc, Ungarn'
        }
    },
    {
        sort: 'Kotin Michaił L.',
        label: {
            pl: 'Prof. dr hab. Michaił L. Kotin, Uniwersytet Zielonogórski, Polska',
            en: 'Prof. dr Michaił L. Kotin, University of Zielona Góra, Poland',
            de: 'Prof. Dr. Michaił L. Kotin, Universitär Zielona Góra'
        }
    },
    {
        sort: 'Kotorova Elizaveta',
        label: {
            pl: 'Prof. dr hab. Elizaveta Kotorova, Uniwersytet Zielonogórski, Polska',
            en: 'Prof. dr hab. Elizaveta Kotorova, University of Zielona Góra, Poland',
            de: 'Prof. Dr. Elizaveta Kotorova, Universität Zielona Góra, Polen'
        }
    },
    {
        sort: 'Kowalska-Szubert Agata',
        label: {
            pl: 'Dr hab. Agata Kowalska-Szubert, Uniwersytet Wrocławski, Polska',
            en: 'Prof. dr Agata Kowalska-Szubert, University of Wrocław, Poland',
            de: 'Dr. habil. Agata Kowalska-Szubert, Universität Wrocław, Polen'
        }
    },
    {
        sort: 'Księżyk Felicja',
        label: {
            pl: 'Dr hab. Felicja Księżyk, Uniwersytet Opolski, Polska',
            en: 'Prof. dr Felicja Księżyk, University of Opole, Poland',
            de: 'Dr. habil. Felicja Księżyk, Univ.-Prof., Universität Oppeln, Polen'
        }
    },
    {
        sort: 'Kubacki Artur Dariusz',
        label: {
            pl: 'Dr hab. Artur Dariusz Kubacki, Prof. UP, Uniwersytet Pedagogiczny im. KEN w Krakowie, Polska',
            en: 'Prof dr hab. Artur Dariusz Kubacki, Pedagogical University of Cracow, Poland',
            de: 'Dr. habil. Artur Dariusz Kubacki, Univ.-Prof., Pädagogische Universität Krakau, Polen'
        }
    },
    {
        sort: 'Kühl Karoline',
        label: {
            pl: 'Prof. dr Karoline Kühl, Uniwersytet we Flensurgu, Niemcy',
            en: 'Prof. dr Karoline Kühl, University of Flensburg, Germany',
            de: 'Prof. Dr. Karoline Kühl, Universität Flensurg, Deutschland'
        }
    },
    {
        sort: 'Kuźniak Marek',
        label: {
            pl: 'Dr. hab. Marek Kuźniak, Uniwersytet Wrocławski, Polska',
            en: 'Prof. dr Marek Kuźniak, University of Wrocław, Poland',
            de: 'Univ.-Prof. Dr. habil. Marek Kuźniak, Universität Wrocław, Polen'
        }
    },
    {
        sort: 'Lenk Hartmut',
        label: {
            pl: 'Prof. dr hab. Hartmut Lenk, Uniwersytet w Helsinkach, Finlandia',
            en: 'Prof. dr hab. Hartmut Lenk, University of Helsinki, Finnland',
            de: 'Prof. Dr. Hartmut Lenk, Universität in Helsinki, Finnland'
        }
    },
    {
        sort: 'Lipavic-Oštir Alja',
        label: {
            pl: 'Prof. dr hab. Alja Lipavic-Oštir, Uniwersytet w Mariborze, Słowenia',
            en: 'Prof. dr Alja Lipavic-Oštir, University of Maribor, Slovenia',
            de: 'Prof. Dr. Alja Lipavic-Oštir, Universität Maribor, Slowenien'
        }
    },
    {
        sort: 'Lipczuk Ryszard',
        label: {
            pl: 'Prof. dr hab. Ryszard Lipczuk, Uniwersytet Szczeciński, Polska',
            en: 'Prof. dr hab. Ryszard Lipczuk, University of Szczecin, Poland',
            de: 'Prof. Dr. Ryszard Lipczuk, Universität Stettin, Polen'
        }
    },
    {
        sort: 'Lisiecka-Czop Magdalena',
        label: {
            pl: 'Dr hab. Magdalena Lisiecka-Czop, Uniwersytet Szczeciński, Polska',
            en: 'Prof. dr Magdalena Lisiecka-Czop, University of Szczecin, Poland',
            de: 'Dr. habil. Magdalena Lisiecka-Czop, Universität Stettin, Polen'
        }
    },
    {
        sort: 'Łopuszańska Grażyna',
        label: {
            pl: 'Prof. dr hab. Grażyna Łopuszańska, Uniwersytet Gdański, Polska',
            en: 'Prof. dr Grażyna Łopuszańska, University of Gdańsk, Poland',
            de: 'Prof. Dr. Grażyna Łopuszańska, Universität Danzig, Polen'
        }
    },
    {
        sort: 'Mac Agnieszka',
        label: {
            pl: 'Dr hab. Agnieszka Mac, prof. Urz, Uniwersytet Rzeszowski, Polska',
            en: 'Porf. dr hab. Agnieszka Mac, University of Rzeszów, Poland',
            de: 'Dr. hab. Agnieszka Mac, Univ.-Prof., Universität Rzeszów, Polen'
        }
    },
    {
        sort: 'Maciejewski Marcin',
        label: {
            pl: 'Dr hab. Marcin Maciejewski, prof. UAM, Uniwersytet im. Adama Mickiewicza w Poznaniu, Polska',
            en: 'Prof. dr Marcin Maciejewski, Adam Mickiewicz University in Poznań, Poland',
            de: 'Dr. habil. Marcin Maciejewski, Univ.-Prof., Adam-Mickiewicz-Universität, Poznań, Polen'
        }
    },
    {
        sort: 'Makowski Jacek',
        label: {
            pl: 'Dr hab. Jacek Makowski, prof. UŁ, Uniwersytet Łódzki, Polska',
            en: 'Prof. dr Jacek Makowski, University of Łódź, Poland',
            de: 'Dr. habil. Jacek Makowski, Univ.-Prof., Universität Lodz, Polen'
        }
    },
    {
        sort: 'Marschall Gottfried',
        label: {
            pl: 'Prof. dr hab. Gottfried Marschall, Uniwersytet Paryski – Sorbonne, Francja',
            en: 'Prof. dr Gottfried Marschall, Paris-Sorbonne University, France',
            de: 'Prof. Dr. habil. Gottfried Marschall, Universität Paris-Sorbonne, Frankreich'
        }
    },
    {
        sort: 'Marx Konstanze',
        label: {
            pl: 'Prof. dr hab. Konstanze Marx, Uniwersytet w Greifswaldzie, Niemcy',
            en: 'Prof. dr hab. Konstanze Marx, University of Greifswald, Germany',
            de: 'Prof. Dr. Konstanze Marx, Universität Greifswald, Deutschland'
        }
    },
    {
        sort: 'Mazurkiewicz-Sokołowska Jolanta',
        label: {
            pl: 'Dr hab. Jolanta Mazurkiewicz-Sokołowska, prof. US, Uniwersytet Szczeciński, Polska',
            en: 'Prof. dr Jolanta Mazurkiewicz-Sokołowska University of Szczecin, Poland',
            de: 'Dr. habil. Jolanta Mazurkiewicz-Sokołowska, Univ.-Prof., Universität Stettin, Polen'
        }
    },
    {
        sort: 'Mehlhorn Grit',
        label: {
            pl: 'Prof. dr hab. Grit Mehlhorn, Uniwersytet Lipski, Niemcy',
            en: 'Prof. dr Grit Mehlhorn, Leipzig University, Germany',
            de: 'Prof. Dr. habil. Grit Mehlhorn, Universität Leipzig, Deutschland'
        }
    },
    {
        sort: 'Mikołajczyk Beata',
        label: {
            pl: 'Dr hab. Beata Mikołajczyk, prof. UAM, Uniwersyet im. Adama Mickiewicza, Polska',
            en: 'Prof. dr Beata Mikołajczyk, Adam Mickiewicza University, Poland',
            de: 'Univ.-Prof. Dr. habil. Beata Mikołajczyk, Adam-Mickiewicz-Universität, Polen'
        }
    },
    {
        sort: 'Mohnkern Ansgar',
        label: {
            pl: 'Prof. dr hab. Ansgar Mohnkern, Universytet w Amsterdamie, Niderlandy',
            en: 'Prof. dr hab. Ansgar Mohnkern, University of Amsterdam, Netherlands',
            de: 'Prof. Dr. Ansgar Mohnkern, Univeristät Amsterdam, Niederlande'
        }
    },
    {
        sort: 'Mostýn Martin',
        label: {
            pl: 'Dr hab. Martin Mostýn, Uniwersytet w Ostravie, Rep. Czeska',
            en: 'Dr hab. Martin Mostýn, University of Ostrava, Czech Republik',
            de: 'Dr. habil. Martin Mostýn, Universität Ostrava, Tschechische Republik'
        }
    },
    {
        sort: 'Nadobnik Renata',
        label: {
            pl: 'Dr hab. Renata Nadobnik, prof. ucz, Akademia im. Jakuba z Paradyża w Gorzowie Wielkopolskim, Polska',
            en: 'Prof. dr Renata Nadobnik, Jacob of Paradies University, Poland',
            de: 'Univ.-Prof. Dr. habil. Renata Nadobnik, Jan-Paradyż-Akademie in Gorzów Wielkopolski, Polen'
        }
    },
    {
        sort: 'Nerlicki Krzysztof',
        label: {
            pl: 'Dr hab. Krzysztof Nerlicki, Uniwersytet Szczeciński, Polska',
            en: 'Prof. dr Krzysztof Nerlicki, University of Szczecin, Poland',
            de: 'Dr. habil. Krzysztof Nerlicki, Univ.-Prof., Universität Stettin, Polen'
        }
    },
    {
        sort: 'Nowakowska-Kempna Iwona',
        label: {
            pl: 'Prof. dr hab. Iwona Nowakowska-Kempna, Uniwersytet Humanistyczno-Przyrodniczy im. Jana Długosza w Częstochowie, Polska',
            en: 'Prof. dr hab. Iwona Nowakowska-Kempna, Jan Długosz University in Częstochowa, Poland',
            de: 'Prof. Dr. Iwona Nowakowska-Kempna, Jan-Długosz-Universität in Częstochowa, Polen'
        }
    },
    {
        sort: 'Nycz Krzysztof',
        label: {
            pl: 'Dr hab. Krzysztof Nycz, prof. URz, Uniwersytet Rzeszowski, Polska',
            en: 'Prof. dr Krzysztof Nycz, University of Rzeszów, Poland',
            de: 'Univ.-Prof. dr. habil. Krzysztof Nycz, Universität Rzeszów, Polen'
        }
    },
    {
        sort: 'Opiłowski Roman',
        label: {
            pl: 'Dr hab. Roman Opiłowski, prof. UWr, Uniwersytet Wrocławski, Polska',
            en: 'Prof. dr Roman Opiłowski, University of Wrocław, Poland',
            de: 'Univ.-Prof. Dr. habil. Roman Opiłowski, Universität Wrocław, Polen'
        }
    },
    {
        sort: 'Ortner Heike',
        label: {
            pl: 'Dr Heike Ortner, Uniwersytet w Insbruku, Austria',
            en: 'Dr Heike Ortner, Unibersity of Innsbruck, Austria',
            de: 'Dr. Heike Ortner, Universität Innsbruck, Österreich'
        }
    },
    {
        sort: 'Owsiński Piotr',
        label: {
            pl: 'Dr Piotr Owsiński, Uniwersytet Jagielloński, Polska',
            en: 'Dr Piotr Owsiński, Jagiellonian University, Poland',
            de: 'Dr. Piotr Owsiński, Jagiellonen-Universität, Polen'
        }
    },
    {
        sort: 'Pavić Pintarić Anita',
        label: {
            pl: 'Prof. dr hab. Anita Pavić Pintarić, Uniwersytet w Zadarze, Chorwacja',
            en: 'Prof. dr Anita Pavić Pintarić, University of Zadar, Croatia',
            de: 'Prof. Dr. Anita Pavić Pintarić, Universität Zadar, Chroatien'
        }
    },
    {
        sort: 'Pawlikowska-Asendrych Elżbieta',
        label: {
            pl: 'Dr hab. Elżbieta Pawlikowska-Asendrych, prof. UJD, Polska',
            en: 'Prof. dr Elżbieta Pawlikowska-Asendrych, Jan Długosz University, Poland',
            de: 'Univ.-Prof. Dr. habil. Elżbieta Pawlikowska-Asendrych, Jan-Długosz-Universität  in Częstochowa, Polen'
        }
    },
    {
        sort: 'Pawłowski Grzegorz',
        label: {
            pl: 'Dr hab. Grzegorz Pawłowski, Uniwersytet Warszawski, Polska',
            en: 'Dr hab. Grzegorz Pawłowski, University of Warsaw, Poland',
            de: 'Dr. habil. Grzegorz Pawłowski, Universität Warschau, Polen'
        }
    },
    {
        sort: 'Pędzisz Joanna',
        label: {
            pl: 'Dr hab. Joanna Pędzisz, Uniwersytet Marii Curie-Skłodowskiej w Lublinie, Polska',
            en: 'Prof. dr Joanna Pędzisz, Maria Curie-Skłodowska-University in Lublin, Poland',
            de: 'Dr. habil. Joanna Pędzisz, Maria-Curie-Skłodowska-Universität in Lublin, Polen'
        }
    },
    {
        sort: 'Pelka Daniela',
        label: {
            pl: 'Dr hab. Daniela Pelka, prof. UO, Uniwersytet Opolski, Polska',
            en: 'Prof. dr Daniela Pelka, University of Opole, Poland',
            de: 'Dr. habil. Daniela Pelka, Univ.-Prof., Universität Oppeln, Polen'
        }
    },
    {
        sort: 'Péteri Attila',
        label: {
            pl: 'Dr hab. Attila Péteri, Uniwersytet Loránda Eötvösa, Budapest, Węgry',
            en: 'Dr Attila Péteri, Eötvös Loránd University Budapest, Hungary',
            de: 'Dr. habil. Attila Péteri, Loránd-Eötvös-Universität, Budapest, Ungarn'
        }
    },
    {
        sort: 'Pieklarz Magdalena',
        label: {
            pl: 'Dr hab. Magdalena Pieklarz, prof. UB, Universytet w Białymstoku, Polska',
            en: 'Prof. dr Magdalena Pieklarz, University of Białystok, Poland',
            de: 'Univ.-Prof. Dr. habil. Magdalena Pieklarz, Universität in Białystok, Polen'
        }
    },
    {
        sort: 'Pilarski Anna',
        label: {
            pl: 'Dr hab. Anna Pilarski, prof. Usz, Uniwersytet Szczeciński, Polska',
            en: 'Prof. dr hab. Anna Pilarski, University of Szczecin, Poland',
            de: 'Dr. habil. Anna Pilarski, Univ.-Prof., Universität Stettin, Polen'
        }
    },
    {
        sort: 'Pociask Janusz',
        label: {
            pl: 'Dr Janusz Pociask, Uniwersytet Kazimierza Wielkiego w Bydgoszczy, Polska',
            en: 'Dr Janusz Pociask, Kazimierz Wielki University in Bydgoszcz, Poland',
            de: 'Dr. Janusz Pociask, Kazimierz-Wielki-Universität in Bydgoszcz, Polen'
        }
    },
    {
        sort: 'Proveja Egita',
        label: {
            pl: 'Dr Egita Proveja, Szkoła Wyższa w Ventspils, Litwa',
            en: 'Dr Egita Proveja, Ventspils University College, Lithuania',
            de: 'Dr. Egita Proveja, Hochschule Ventspils, Litauen'
        }
    },
    {
        sort: 'Reimann Sandra',
        label: {
            pl: 'Prof. dr hab. Sandra Reimann, Uniwersytet w Oulu, Finlandia',
            en: 'Prof. dr hab. Sandra Reimann, University of Oulu, Finland',
            de: 'Prof. Dr. Sandra Reimann, Universität Oulu, Finnland'
        }
    },
    {
        sort: 'Rejter Artur',
        label: {
            pl: 'Prof. dr hab. Artur Rejter, Uniwersytet Śląski, Polska',
            en: 'Prof. dr hab. Artur Rejter, University in Katowice, Poland',
            de: 'Prof. Dr. Artur Rejter, Schlesische Universität, Polen'
        }
    },
    {
        sort: 'Richter Nicole',
        label: {
            pl: 'Prof. dr hab. Nicole Richter, Uniwersytet Europejski Viadrina we Frankfurcie nad Odrą, Niemcy',
            en: 'Prof. Nicole Richter, European University Viadrina Frankfurt (Oder), Germany',
            de: 'Prof. Dr. Nicole Richter, Europa-Universität Viadrina in Frankfurt (Oder), Deutschland'
        }
    },
    {
        sort: 'Rinas Karsten',
        label: {
            pl: 'Dr hab. Karsten Rinas, Uniwersytet Palackiego w Ołomuńcu, Republika Czeska',
            en: 'Prof. dr hab. Karsten Rinas, Palacký University Olomouc, Czech Republic',
            de: 'Dr. habil. Karsten Rinas, Palacký-Universität Olmütz, Tschechische Republik'
        }
    },
    {
        sort: 'Rothenhagen Richard',
        label: {
            pl: 'Dr Richard Rothenhagen, Uniwersytet Masaryka, Brno, Republika Czeska',
            en: 'Dr Richard Rothenhagen, Masaryk University, Brno, Czech Republic',
            de: 'Dr. Richard Rothenhagen, Masaryk-Universität, Brünn, Tschechien'
        }
    },
    {
        sort: 'Rutka Anna',
        label: {
            pl: 'Dr hab. Anna Rutka, prof. KUL, Katolicki Uniwersytet Lubelski, Polska',
            en: 'Prof. dr hab. Anna Rutka, John Paul II Catholic University of Lublin, Poland',
            de: 'Dr. habil. Anna Rutka, Univ.-Prof., Katholische Universität Lublin Johannes Paul II., Polen'
        }
    },
    {
        sort: 'Rutkowski Mariusz',
        label: {
            pl: 'Prof. dr hab. Mariusz Rutkowski, Uniwersytet Warmińsko-Mazurski w Olsztynie, Polska',
            en: 'Prof. dr Mariusz Rutkowski, University of Warmia and Mazury in Olsztyn, Poland',
            de: 'Prof. Dr. Mariusz Rutkowski, Warmia und Mazury-Universität, Olsztyn, Polen'
        }
    },
    {
        sort: 'Sadiku Milote',
        label: {
            pl: 'Dr hab. Milote Sadiku, prof. ucz., Uniwersytet w Prisztinie, Kosowo',
            en: 'Prof. dr hab. Milote Sadiku, University of Prishtina, Kosovo',
            de: 'Univ.-Prof. Dr. habil. Milote Sadiku, Universität Prishtina, Kosovo'
        }
    },
    {
        sort: 'Sadziński Roman',
        label: {
            pl: 'Prof. dr hab. Roman Sadziński, Uniwersytet Łódzki, Polska',
            en: 'Prof. dr Roman Sadziński, University of Łódź, Poland',
            de: 'Prof. Dr. Roman Sadziński, Universität Lodz, Polen'
        }
    },
    {
        sort: 'Schaeder Burkhard',
        label: {
            pl: '† Prof. dr hab. Burkhard Schaeder, Uniwersytet Siegen, Niemcy',
            en: '† Prof. dr Burkhard Schaeder, University of Siegen, Germany',
            de: '† Prof. Dr. habil. Burkhard Schaeder, Universität Siegen, Deutschland'
        }
    },
    {
        sort: 'Sepp Arvi',
        label: {
            pl: 'Prof. dr hab. Arvi Sepp, Uniwersytet w Brukseli, Belgia',
            en: 'Prof. dr hab. Arvi Sepp, University of Brussels, Belgium',
            de: 'Prof. Dr. Arvi Sepp, Universität Brüssel, Belgien'
        }
    },
    {
        sort: 'Sitek Marek',
        label: {
            pl: 'Dr Marek Sitek, Uniwersytet Opolski, Polska',
            en: 'Dr Marek Sitek, University of Opole, Poland',
            de: 'Dr. Marek Sitek, Universität Oppeln, Polen'
        }
    },
    {
        sort: 'Skoczek Robert',
        label: {
            pl: 'Dr hab. Robert Skoczek, prof. UW, Uniwersytet Warszawski, Polska',
            en: 'Prof. dr Robert Skoczek, University of Warsaw, Poland',
            de: 'Univ.-Prof. Dr. habil. Robert Skoczek, Universität Warschau, Polen'
        }
    },
    {
        sort: 'Sommerfeld Bete',
        label: {
            pl: 'Dr hab. Bete Sommerfeld, Uniwersytet im. Adama Miskiewicza w Poznaniu, Polska',
            en: 'Prof. dr hab. Beate Bete Sommerfeld, Adam Mickiewicz University in Poznań, Poland',
            de: 'Dr. habil. Beate Sommerfeld, Univ.-Prof., Adam-Mickiewicz Universität in Poznań, Polen'
        }
    },
    {
        sort: 'Sopata Aldona',
        label: {
            pl: 'Dr hab. Aldona Sopata, prof. UAM, Uniwersytet im. Adama Mickiewicza w Poznaniu, Polska',
            en: 'Prof. dr hab. Aldona Sopata, Adam Mickiewicz University, Poland',
            de: 'Univ.-Prof. Dr. habil. Aldona Sopata, Adam-Mickiewicz-Universität Posen, Polen'
        }
    },
    {
        sort: 'Staniewski Przemysław',
        label: {
            pl: 'Dr Przemysław Staniewski, Uniwersytet Wrocławski, Polska',
            en: 'Dr Przemysław Staniewski, University of Wrocław, Poland',
            de: 'Dr. Przemysław Staniewski, Universität Wrocław, Polen'
        }
    },
    {
        sort: 'Steyer Kathrin',
        label: {
            pl: 'Dr Kathrin Steyer, Instytut Języka Niemieckiego im. Leibniza, Niemcy',
            en: 'Dr Kathrin Steyer, Leibniz Institute for the German Language, Germany',
            de: 'Dr. Kathrin Steyer, Leibnitz-Institut für Deutsche Sprache Mannheim, Deutschland'
        }
    },
    {
        sort: 'Sulikowska Anna',
        label: {
            pl: 'Dr hab. Anna Sulikowska, Uniwersytet Szczeciński, Polska',
            en: 'Dr hab. Anna Sulikowska, University of Szczecin, Poland',
            de: 'Dr. habil. Anna Sulikowska, Universität Stettin, Polen'
        }
    },
    {
        sort: 'Sulikowski Piotr',
        label: {
            pl: 'Dr hab. Piotr Sulikowski, prof. US, Uniwersytet Szczeciński, Polska',
            en: 'Prof. dr hab. Piotr Sulikowski, University of Szczecin, Poland',
            de: 'Dr. habil. Piotr Sulikowski, Unic.-Prof., Universität Stettin, Polen'
        }
    },
    {
        sort: 'Sułkowska Monika',
        label: {
            pl: 'Dr hab. Monika Sułkowska, prof. UŚ, Uniwersytet Śląski w Katowicach, Polska',
            en: 'Prof. dr Monika Sułkowska, Silesian University in Katowice, Poland',
            de: 'Univ.-Prof. Dr. habil. Monika Sułkowska, Schlesichsche Universität Katowice, Polen'
        }
    },
    {
        sort: 'Szczepaniak Jacek',
        label: {
            pl: 'Dr hab. Jacek Szczepaniak, prof. UKW w Bydgoszczy, Polska',
            en: 'Prof. dr Jacek Szczepaniak, Kazimierz Wielki University in Bydgoszcz, Poland',
            de: 'Dr. habil. Jacek Szczepaniak, Univ.-Prof., Kazimierz-Wielki Universität in Bzdgoszcz, Polen'
        }
    },
    {
        sort: 'Szczepaniak-Kozak Anna',
        label: {
            pl: 'Dr hab. Anna Szczepaniak-Kozak, prof. UAM, Uniwersytet Adama Mickiewicza w Poznaniu, Polska',
            en: 'Prof. dr Anna Szczepaniak-Kozak, Adam Mickiewicz University, Poland',
            de: 'Univ.-Prof. Dr. habil. Anna Szczepaniak-Kozak, Adam-Mickiewicz-Universität Posen, Polen'
        }
    },
    {
        sort: 'Szczyszek Michał',
        label: {
            pl: 'Dr hab. Michał Szczyszek, prof. UAM, Uniwerystet im Adama Mickiewicza w Poznaniu, Polska',
            en: 'Prof. dr Joanna Szerszunowicz, University of Białystok, Poland',
            de: 'Dr. habil. Joanna Szerszunowicz, Universität in Białystok, Polen'
        }
    },
    {
        sort: 'Szerszunowicz Joanna',
        label: {
            pl: 'Dr hab. Joanna Szerszunowicz, Uniwersytet w Białymstoku',
            en: 'Prof. dr hab. Michał Szczyszek, Adam Mickiewicz University in Poznań, Poland',
            de: 'Dr. habil. Michał Szczyszek, Univ.-Prof., Adam-Mickiewicz-Universität in Poznań, Polen'
        }
    },
    {
        sort: 'Szpila Grzegorz',
        label: {
            pl: 'Dr hab. Grzegorz Szpila, prof. UJ, Uniwersytet Jagielloński, Polska',
            en: 'Prof. dr Grzegorz Szpila, Jagiellonian University, Poland',
            de: 'Univ.-Prof. Dr. habil. Grzegorz Szpila, Jagiellonen-Universität, Polen'
        }
    },
    {
        sort: 'Szubert Andrzej',
        label: {
            pl: 'Dr hab. Andrzej Szubert, prof. UAM, Uniwersytet im. Adama Mickiewicza w Poznaniu, Polska',
            en: 'Prof. dr Andrzej Szubert, Adam Mickiewicz University Poznań, Poland',
            de: 'Univ.-Prof. Dr. habil. Andrzej Szubert, Adam-Mickiewicz Universität Posen, Polen'
        }
    },
    {
        sort: 'Taborek Janusz',
        label: {
            pl: 'Dr. hab. Janusz Taborek, Uniwersytet im. Adama Mickiewicza w Poznaniu, Polska',
            en: 'Prof. dr Janusz Taborek, Adam Mickiewicz University in Poznań, Poland',
            de: 'Dr. habil. Janusz Taborek, Univ.-Prof.,Adam-Mickiewicz-Universität Posen, Polen'
        }
    },
    {
        sort: 'Targońska Joanna',
        label: {
            pl: 'dr hab. Joanna Targońska, Uniwersytet Warmińsko-Mazurski w Olsztynie, Polska',
            en: 'Prof. dr Joanna Targońska, University of Warmia und Mazury in Olsztyn, Poland',
            de: 'Dr. habil. Joanna Targońska, Warmia und Mazury-Universität in Olsztyn, Polen'
        }
    },
    {
        sort: 'Tienken Susanne',
        label: {
            pl: 'Prof. dr hab. Susanne Tienken, Uniwersytet w Sztokholmie, Szwecja',
            en: 'Prof. dr hab. Susanne Tienken, University of Stockholm, Sweden',
            de: 'Prof. Dr. Susanne Tienken, Universität Stockholm, Schweden'
        }
    },
    {
        sort: 'Tuora-Schwierskott Ewa',
        label: {
            pl: 'Prof. dr hab. Ewa Tuora-Schwierskott, Niemiecko-Polskie Stowarzyszenie Prawników, Berlin, Niemcy',
            en: 'Prof. dr hab. Ewa Tuora-Schwierskott, German-Polish Lawyers’ Association, Berlin, Germany',
            de: 'Prof. Dr. Ewa Tuora-Schwierskott, Deutsch-Polnische Juristen-Vereinigung, Berlin, Deutschland'
        }
    },
    {
        sort: 'Ulrich Winfried',
        label: {
            pl: 'Prof. dr dr h.c. mult. Winfried Ulrich, Uniwersytet Chrystiana Albrechta w Kilonii, Niemcy',
            en: 'Prof. Dr. Dr. h.c. mult. Winfried Ulrich, Christian-Albrecht University of Kiel, Germany',
            de: 'Prof. Dr. Dr. h.c. mult. Winfried Ulrich, Christian-Albrechts-Universität zu Kiel, Deutschland'
        }
    },
    {
        sort: 'Utri Reinhold',
        label: {
            pl: 'Dr hab. Reinhold Utri, Uniwersytet Warszawski, Polska',
            en: 'Dr hab. Reinhold Utri, University of Warsaw, Poland',
            de: 'Dr. habil. Reinhold Utri, Universität Warschau, Polen'
        }
    },
    {
        sort: 'Walczyński Marcin',
        label: {
            pl: 'Dr hab. Marcin Walczyński, Uniwersytet Wrocławski, Polska',
            en: 'Dr hab. Marcin Walczyński, University of Wrocław, Poland',
            de: 'Dr. habil. Marcin Walczyński, Universität Wrocław, Polen'
        }
    },
    {
        sort: 'Weigt Zenon',
        label: {
            pl: 'Dr hab. Zenon Weigt, prof. UŁ, Uniwersytet Łódzki, Polska',
            en: 'Prof. dr hab. Zenon Weigt, University of Lodz, Poland',
            de: 'Dr. habil. Zenon Weigt, Univ-Prof., Universität Lodz, Polen'
        }
    },
    {
        sort: 'Wierzbicka Mariola',
        label: {
            pl: 'Prof. dr hab. Mariola Wierzbicka, Uniwersytet Rzeszowski, Polska',
            en: 'Prof. dr Mariola Wierzbicka, Universiy of Rzeszów, Poland',
            de: 'Prof. Dr. Mariola Wierzbicka, Universität Rzeszów, Polen'
        }
    },
    {
        sort: 'Wiktorowicz Józef',
        label: {
            pl: 'Prof. dr hab. Józef Wiktorowicz, Uniwersytet Warszawski, Polska',
            en: 'Prof. dr hab. Józef Wiktorowicz, University of Warsaw, Poland',
            de: 'Prof. dr hab. Józef Wiktorowicz, Universität Warschau, Polen'
        }
    },
    {
        sort: 'Wolting Monika',
        label: {
            pl: 'Dr hab. Monika Wolting, prof. UWr, Uniwersytet Wrocławski, Polska',
            en: 'Prof. dr hab. Monika Wolting, University of Wrocław, Poland',
            de: 'Dr. habil. Monika Wolting, Univ.-Prof., Universität Wrocław, Polen'
        }
    },
    {
        sort: 'Wowro Iwona',
        label: {
            pl: 'Dr hab. Iwona Wowro, Uniwersytet Śląski, Polska',
            en: 'Prof. dr Iwona Wowro, Silesian University in Katowice, Poland',
            de: 'Dr. habil. Iwona Wowro, Schlesische Universität, Polen'
        }
    },
    {
        sort: 'Zakrajewski Paweł',
        label: {
            pl: 'Dr Paweł Zakrajewski, Uniwersytet Śląski w Katowicach, Polska',
            en: 'Dr Paweł Zakrajewski, Silesian University in Katowice, Poland',
            de: 'Dr. Paweł Zakrajewski, Schlesische Universität Katowice, Polen'
        }
    },
    {
        sort: 'Zaśko-Zielińska Monika',
        label: {
            pl: 'Dr hab. Monika Zaśko-Zielińska, prof. UWr, Uniwersytet Wrocławski, Polska',
            en: 'Prof. dr hab. Monika Zaśko-Zielińska, University of Wrocław, Poland',
            de: 'Dr. habil. Monika Zaśko-Zielińska, Univ.-Prof., Universität Wrocław, Polen'
        }
    },
    {
        sort: 'Żebrowska Ewa',
        label: {
            pl: 'Prof. dr hab. Ewa Żebrowska, Uniwersytet Warszawski, Polska',
            en: 'Prof. dr hab. Ewa Żebrowska, University of Warsaw, Poland',
            de: 'Prof. Dr. Ewa Żebrowska, Universität Warschau, Polen'
        }
    },
    {
        sort: 'Zieliński Lech',
        label: {
            pl: 'Dr hab. Lech Zieliński, prof. UMK, Uniwersyet im. Mikołaja Kopernika w Toruniu, Polska',
            en: 'Prof. dr hab. Lech Zieliński, Nicolaus Copernicus University in Toruń, Poland',
            de: 'Dr. habil. Lech Zieliński, Univ.-Prof., Nicolaus-Copernicus-Universität in Toruń, Polen'
        }
    },
    {
        sort: 'Żytyńska Małgorzata',
        label: {
            pl: 'Dr Małgorzata Żytyńska, Uniwersytet Łódzki, Polska',
            en: 'Dr Małgorzata Żytyńska, University of Łódź, Poland',
            de: 'Dr. Małgorzata Żytyńska, Universität Łódź, Polen'
        }
    },
    {
        sort: 'Płomińska Małgorzata',
        label: {
            pl: 'Dr hab. Małgorzata Płomińska, Uniwersytet Śląski, Polska',
            en: 'Dr hab. Małgorzata Płomińska, University of Silesia, Poland',
            de: 'Dr. habil. Małgorzata Płomińska, Schlesische Universität Katowice, Polen'
        }
    },
    {
        sort: 'Słabońska Olga',
        label: {
            pl: 'Dr Olga Słabońska, Uniwersytet Jana Długosza w Częstochowie, Polska',
            en: 'Dr Olga Słabońska, Jan Długosz University in Częstochowa, Poland',
            de: 'Dr. Olga Słabońska, Jan-Długosz-Universität, Polen'
        }
    },
    {
        sort: 'Stawikowska-Marcinkowska Agnieszka',
        label: {
            pl: 'Dr Agnieszka Stawikowska-Marcinkowska, Uniwersytet Łódzki, Polska',
            en: 'Dr Agnieszka Stawikowska-Marcinkowska, University of Łódź, Poland',
            de: 'Dr. Agnieszka Stawikowska-Marcinkowska, Universität Łódź, Polen'
        }
    },
    {
        sort: 'Młynarczyk Ewa',
        label: {
            pl: 'Prof. dr hab. Ewa Młynarczyk, Uniwersytet im. KEN w Krakowie, Polska',
            en: 'Prof. dr hab. Ewa Młynarczyk, Pedagogical University of Kraków, Poland',
            de: 'Prof. Dr. Ewa Młynarczyk, Pädagogische Universität Krakau, Polen'
        }
    },
    {
        sort: 'Pacuła Jarosław',
        label: {
            pl: 'Dr hab. Jarosław Pacuła, prof. UŚ, Uniwersytet Śląski, Polska',
            en: 'Prof. dr Jarosław Pacuła, University of Silesia, Poland',
            de: 'Univ.-Prof. Dr. habil. Jarosław Pacuła, Schlesische Universität Katowice, Polen'
        }
    },
    {
        sort: 'Ernst Peter',
        label: {
            pl: 'Prof. dr hab. Peter Ernst, Uniwersytet Wiedeński, Austria',
            en: 'Prof. dr hab. Peter Ernst, University of Vienna, Austria',
            de: 'Prof. Dr. Peter Ernst, Universität Wien, Österreich'
        }
    },
    {
        sort: 'Kujawa Izabela',
        label: {
            pl: 'Dr Izabela Kujawa, Uniwersytet Gdański, Polska',
            en: 'Dr Izabela Kujawa, University of Gdańsk, Poland',
            de: 'Dr. Izabela Kujawa, Universität Gdańsk, Polen'
        }
    },
    {
        sort: 'Kic-Drgas Joanna',
        label: {
            pl: 'Dr hab. Joanna Kic-Drgas, Uniwersytet im. Adama Mickiewicza w Poznaniu, Polska',
            en: 'Dr hab. Joanna Kic-Drgas, Adam Mickiewicz University in Poznań, Poland',
            de: 'Dr. habil. Joanna Kic-Drgas, Adam-Mickiewicz-Universität in Poznań, Polen'
        }
    },
    {
        sort: 'Petkova Mikaela',
        label: {
            pl: 'Dr Mikaela Petkova, Uniwersytet w Soffi, Bułgaria',
            en: 'Dr Mikaela Petkova, University of Sofia, Bulgaria',
            de: 'Dr. Mikaela Petkova, Sofioter Universität, Bulgarien'
        }
    },
    {
        sort: 'Woźniak Joanna',
        label: {
            pl: 'Dr Joanna Woźniak, Uniwersytet im. Adama Mickiewicza w Poznaniu, Polska',
            en: 'Dr Joanna Woźniak, Adam Mickiewicz University in Poznań, Poland',
            de: 'Dr. Joanna Woźniak, Adam-Mickiewicz-Universität in Poznań, Polen'
        }
    }
].sort((a, b) =>
    getLatinized((a.sort).toLowerCase()) >
    getLatinized((b.sort).toLowerCase()) ? 1 : -1
);

export default reviewers;
